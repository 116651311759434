import React, { ReactElement, useState } from 'react'
import { Radio } from "../../components/FormInputs"
import { Button, Grid, GridItem, Text, useBreakpointValue } from "@chakra-ui/react"
import { RootState } from "../../store/modules/rootReducer"
import { connect, ConnectedProps } from 'react-redux'

const mapState = (state: RootState) => {
  return {
    form: state.form,
    errors: state.error
  }
}

type PropsFromRedux = ConnectedProps<typeof connector>

const connector = connect(mapState)

const StepThree = ({ form, errors }: PropsFromRedux): ReactElement => {

  const [has36OrMoreFGTSContributions, setHas36OrMoreFGTSContributions] = useState<string>(form.has36OrMoreFGTSContributions) 

  const templateAreas = useBreakpointValue({ 
    lg: `'has36OrMoreFGTSContributions   has36OrMoreFGTSContributions   . . .'
    'hasAccountOrFGTSHigherThanTenPercentValue hasAccountOrFGTSHigherThanTenPercentValue . . .'
    'goBack .   .   . submit'`,
    md: `'has36OrMoreFGTSContributions   has36OrMoreFGTSContributions   . .'
    'hasAccountOrFGTSHigherThanTenPercentValue hasAccountOrFGTSHigherThanTenPercentValue . .'
    'goBack . . submit'`,
    base: `'has36OrMoreFGTSContributions'
    'hasAccountOrFGTSHigherThanTenPercentValue'
    'submit'
    'goBack'`
  })

  return (
    <Grid
      padding="8"
      gridGap="2"
      gridTemplateAreas={templateAreas}
    > 
      <GridItem gridArea="has36OrMoreFGTSContributions">
        <Text fontWeight="500">Possui conta do FGTS com 36 ou mais contribuições?</Text>
        <Radio
          defaultValue={form.has36OrMoreFGTSContributions}
          name="has36OrMoreFGTSContributions"
          options={[
            { label: "Sim", value: 'true' },
            { label: "Não", value: 'false' },
          ]}
          error={errors.has36OrMoreFGTSContributions}
          onChange={(test) => setHas36OrMoreFGTSContributions(test.target.value) }
        />
      </GridItem>

      { 
        ['true', true, '1', 1].includes(has36OrMoreFGTSContributions) ? (
          <GridItem gridArea="hasAccountOrFGTSHigherThanTenPercentValue">
            <Text fontWeight="500">Possui conta ativa ou saldo no FGTS superior a 10% do valor do imóvel?</Text>
            <Radio
              defaultValue={form.hasAccountOrFGTSHigherThanTenPercentValue}
              name="hasAccountOrFGTSHigherThanTenPercentValue"
              options={[
                { label: "Sim", value: 'true' },
                { label: "Não", value: 'false' },
              ]}
              error={errors.hasAccountOrFGTSHigherThanTenPercentValue}
            />
          </GridItem>
        ) : ""
      }      
      <Button colorScheme="secondary" variant="solid" type="reset" gridArea="goBack">
        Voltar
      </Button>
      <Button colorScheme="secondary" variant="solid" type="submit" gridArea="submit" >
        Proximo
      </Button>
    </Grid>
  )}

export default connector(StepThree)