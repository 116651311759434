import React from 'react'
import { Button, Grid, GridItem, Text, useBreakpointValue } from "@chakra-ui/react"
import { Select, DatePicker } from '../../components/FormInputs'

const Initial = ({ loading, errors }: { loading: boolean, errors: any }) =>{

  // const [product, setProduct] = useState('home')
  // const [personType, setPersonType] = useState('')  

  const templateAreas = useBreakpointValue({
    lg: `'.  .  type       type       type      type       .  .' 
         '.  .  personType personType birthdate birthdate  .  .'
         '.  .  .     .     .    .     .  .'
         '.  .  submit     submit     submit    submit     .  .'`,
    md: `'.  type       type       type      type       .' 
        '.  personType personType birthdate birthdate  .'
        '.  submit     submit     submit    submit     .'`,
    base: `
    'type'
    'personType'
    'birthdate'
    'submit'`
  })

  return (
    <Grid
      padding="8" 
      gridGap="2"
      gridTemplateAreas={templateAreas}
    >
      <GridItem gridArea="type">
        <Text fontWeight="500">Tipo:</Text>
        <Select 
          name="product" 
          error={errors.product}
          placeholder="Selecione o tipo de casa"
          options={[
            { label: 'Casa', value: 'home' },
            { label: 'Apartamento', value: 'apartment' }
          ]}                   
        />
      </GridItem>

      <GridItem gridArea="personType">
        <Text fontWeight="500">Tipo de Pessoa:</Text>        
          <Select 
            error={errors.personType}
            placeholder="Escolha o tipo de pessoa"
            name="personType" 
            options={[
              { label: 'Física', value: 'fisica' },
              { label: 'Jurídica', value: 'juridica' }
            ]}             
          />                  
      </GridItem>

      <GridItem gridArea="birthdate" display="flex" flexDirection="column" alignContent='flex-end' width='100%'>
        <Text fontWeight="500">Data de Nascimento:</Text>
        <DatePicker                    
          dateFormat="dd/MM/yyyy"
          name='birthdate'
          placeholderText="Ex: 01/01/1985"
        />
        {errors.birthdate && (
          <label className="birthdate-error">
            {errors.birthdate}
          </label>)
        }
      </GridItem>      

      <Button 
        colorScheme="secondary" 
        variant="solid" 
        type="submit" 
        gridArea="submit"
        isLoading={loading}
      >
        Continuar
      </Button>
    </Grid>
  )
}

export default Initial