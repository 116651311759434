import React, { ReactElement, ComponentType } from 'react'
import { Route, RouteProps } from 'react-router-dom'
import { Simulation } from '../layouts'


interface Props extends Omit<RouteProps, 'component'> {
  component: ComponentType
}

export default function Routes ({ component: Component, ...rest }: Props): ReactElement {
  return (
    <Route
      {...rest}
      render={() => (
        <Simulation>
          <Component {...rest}/>
        </Simulation>
      )}
    />
  )
}