import React from 'react'
import { IIconProps } from './Icons'

function ArrowLeft (props: IIconProps) {
  const { height, width, fill} = props
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={height || "24px"} viewBox="0 0 24 24" width={width || "24px"} fill={fill || "#000000"}>
      <path d="M0 0h24v24H0V0z" fill="none"/>
      <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12l4.58-4.59z"/>
    </svg>
  )
}

export default ArrowLeft 

