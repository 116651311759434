const processEnv = {
  env: process.env.REACT_APP_ENV,
  mainServer: process.env.REACT_APP_MAIN_SERVER,
  mainToken: process.env.REACT_APP_MAIN_TOKEN,
  lineServer: process.env.REACT_APP_LINE_SERVER,
  shortenUrl: process.env.REACT_APP_SHORTEN_URL,

  rodobensServer: process.env.REACT_APP_RODOBENS_URL,
  jiveServer: process.env.REACT_APP_JIVE_URL
} 

export default processEnv