import translate from './translate'

const format = {
  toBoolean: (value: string) => {
    if ([true, 'true', '1', 1].includes(value)) return true
    if ([false, 'false', '0', 0].includes(value)) return false

    return value
  },

  booleanToString:(value: string)=> {
    if([true, 'true', '1', 1].includes(value)) return "true"
    if ([false, 'false', '0', 0].includes(value)) return "false"

    return value
  },

  onlyDigits: (value: string) => value.replace(/\D/g, ''),

  toPercentage: (value?: number) => {
    if (!value) return "0 %"

    let percentage = value * 100;

    let stringyfied = String(Number(percentage).toFixed(4))
    let [real, decimal] = stringyfied.split('.')

    return `${real},${decimal} %`
  },

  toReal: (value?: number) => {
    if (!value) return "R$ 00,00"

    let stringyfied = String(Number(value).toFixed(2))
    let [real, decimal] = stringyfied.split('.')

    let splited = real.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");

    return `R$ ${splited},${decimal}`
  },

  formatBody: (key: string, value: string) => {
    switch (key) {
      case 'phone':
      case 'cpf':
        return format.onlyDigits(value)
      // case 'value':
      //   return Number(format.onlyDigits(value))
      case 'isNew':
        return format.toBoolean(value)
      default:
        return value
    }
  },

  whatsMessage: (values: any) => {
    const {
      number,
      totalValue,
      financing,
      deadline,
      anualTax,
      maxParcelValue,
      minimunIncome,
      entry,
      name,
      type,
      amortizationType,
      cpf
    } = values

    const message =
      `Olá${name ? `, me chamo *${name}*` : ""}, meu CPF é *${cpf}*.
Tenho interesse em financiamento imobiliário.

*Detalhes da simulação*
    - Valor financiado: ${format.toReal(Number(financing))}
    - Valor de entrada: ${format.toReal(Number(entry))}
    - Prazo: ${deadline} meses
    - Taxa anual: ${anualTax * 100}%
    - Maior parcela: ${format.toReal(Number(maxParcelValue))}
    - Renda minima: ${format.toReal(Number(minimunIncome))}
    - Pagamento total: ${format.toReal(Number(totalValue))}
    - Tipo de imóvel: ${translate.formType(type)}
    - Tipo de amortization: ${amortizationType?.toUpperCase()}

_Simulação_
_${number}_
`
    return encodeURI(message)
  },

  whatsappLink: (message: string, cellphone: string) => {
    return `https://api.whatsapp.com/send?phone=55${cellphone}&text=${message}`
  },

  formatBRLMinAndMax: (value: number | undefined): string => {
    if (!value) return 'R$ ' + 0
    return (value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 0 })
  },

  formatMoneySend(value?: string) {
    if (!value) return 0
    let convertValue = String(value)
    const hasComma = convertValue.indexOf(',') !== -1
  
    if (convertValue.indexOf('R$') !== -1) {
      const temp = convertValue.split(',')
      temp[0] = temp[0].replace(/\D/g, '')
      convertValue = temp.join('.')
    } else { if (hasComma) convertValue = convertValue.replace(/,/g, '.') }
  
    const numberValue = Number(convertValue)
    return Number(numberValue)
  },

  formatSendIdDocument(document?: string){
    if (!document) return ''
    return document.replace(/[^a-z\d]/ig, '')
  }
}


export default format