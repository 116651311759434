import styled from "@emotion/styled"

interface Props extends Omit<React.InputHTMLAttributes<any>, "name"> {
  focusBorderColor?: string
  errorBorderColor?: string
}

export const InputStyle = styled.div<Props>`
  width:100%;
  input{
    width: 100%;
    outline: 0;
    position: relative;
    appearance: none;
    transition: all 0.2s;
    font-size: 0.875rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    height: 2rem;
    border-radius: 0.125rem;
    border: 1px solid;
    border-color: inherit;
    background: #f8f8f9;
    &:focus{
      border: 1px solid ${props => props.focusBorderColor} !important;
      box-shadow: 0 0 0 1px ${props => props.focusBorderColor};
    }
  }
`