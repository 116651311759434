import React from 'react'
import { 
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  Image,
  Box
} from "@chakra-ui/react"

import MoneyImg from '../../assets/images/money-bag.png'

interface IModalIncomeProps {
  isOpen: boolean
  onClose: ()=>void
}

function ModalIncome({isOpen, onClose }:IModalIncomeProps) {
  return (
    <Modal 
    isOpen={isOpen} 
    onClose={onClose}
  >
    <ModalOverlay />
    <ModalContent>
      <ModalHeader></ModalHeader>
      <ModalCloseButton />
      <ModalBody marginLeft='auto' marginRight='auto'>
        <Box display="flex" alignItems="center" flexDirection="column">
          <Image boxSize="200px" src={MoneyImg} alt="Ilustração de dinheiro"/>
          <Text textAlign="center" color="secondary.400" fontWeight="600" fontSize="1.5rem" mt="1.5rem" mb="1rem">{('Renda Total').toUpperCase()}</Text>
          <Text textAlign="justify" fontSize="1rem"> A renda total é a soma da renda bruta individual de todos os compradores que compõe renda na proposta de financiamento.</Text>
          <Text textAlign="justify" fontSize="1rem"> A renda bruta individual se refere ao salário de cada um dos compradores antes de o valor sofrer os descontos dos impostos.</Text>
        </Box>
      </ModalBody>
    </ModalContent>
  </Modal>
  )
}

export default ModalIncome
