import React, { ChangeEvent, Fragment, useEffect, useState } from 'react'
import { connect, ConnectedProps, useDispatch } from 'react-redux'
import { Dispatch } from "redux"

import { 
  Button, 
  Grid, 
  GridItem, 
  Text, 
  useBreakpointValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Switch,
  ModalFooter,
  Box,
  RadioGroup,
  Radio
} from '@chakra-ui/react'
import LoopIcon from '@material-ui/icons/Loop'

import { Table } from '../../components/Table'
import { TextInput, Select } from '../../components/FormInputs'

import { setBeforeStep } from '../../store/modules/step/actions'
import { setError } from '../../store/modules/error/actions'
import { RootState } from "../../store/modules/rootReducer"
import { IError } from '../../store/modules/error/types'
import { ISimulatorSet } from '../../store/modules/simulator/types'
import { setSimulator } from '../../store/modules/simulator/actions'

import format from '../../utils/format'
import interate from '../../utils/interate'

import mainApi from '../../services/mainApi'
import { layout } from '../../config/projectStyle'
import theme from '../../assets/styles/theme'
import dayjs from 'dayjs'
import InputMoney from '../../components/InputMoney/InputMoney'
import { setFormItem } from '../../store/modules/form/actions'

import Cookies from 'universal-cookie'
import Swal from 'sweetalert2'

const mapState = (state: RootState) => {
  return {
    form: state.form,
    simulator: state.simulator,
    errors: state.error,
    line: state.line,
    product: state.product,
    global: state.global
  }
}
const mapDispatch = (dispatch: Dispatch) => ({
  setBeforeStep: () => dispatch(setBeforeStep()),
  setErro: (errors: IError) => dispatch(setError(errors)),
  setSimulator: (simulator: ISimulatorSet) => dispatch(setSimulator(simulator)),
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface IProps extends PropsFromRedux {
  setStep: any
}

const connector = connect(mapState, mapDispatch)

const Result = ({ setBeforeStep, setSimulator, simulator, product, form, setErro, errors, line, setStep, global }: IProps) => {
  const [financy, setFinancy] = useState<string | number>('')
  const [deadline, setDeadline] = useState<string | number>('')
  const [type, setType] = useState<string>('sac')
  const [disableRecalculate, setDisableRecalculate] = useState<boolean>(true)
  const [recalculated, setRecalculated] = useState<boolean>(false)
  const [loadingButton, setLoadingButton] = useState<boolean>(false)
  const [showParcels, setShowParcels] = useState<boolean>(false)
  const [loadingClient, setLoadingClient] = useState<boolean>(false)
  const [flatRate, setFlatRate] = useState<boolean>(false)
  const [financialRegister, setFinancialRegister] = useState<boolean>(false)
  
  const [openConfirm, setOpenConfirm] = useState(false)
  const [changeEmail, setChangeEmail] = useState(false)
  const [confirmEmail, setConfirmEmail] = useState<string>()
  const [loading, setLoading] = useState<boolean>(false)
  const cookies = new Cookies()
  
  const dispatch = useDispatch()

  const newSimulation = async (finish: boolean) => {
    
    let productBody = product.slug ? { product: product.slug } : {}

    const data: any = {      
      financing: financy,
      deadline,
      finish,              
      email: confirmEmail, 
      changeEmail,
      ...productBody      
    }

    dispatch(setFormItem({key:'confirmEmail', value: confirmEmail}))

    if (data.financing) data.financing = format.formatMoneySend(data.financing)

    if(!finish) {
      setLoadingButton(true)
    }
    
    data.flatRate = flatRate
    data.financialRegister = financialRegister
    data.indikyLinkId = global.agxHash || '612011e164de0a5bdc32a7cb'

    setDisableRecalculate(true)        
    
    try {
      setLoading(true)
      const response = await mainApi.post(`/simulation/${simulator.entryId}/${type}`, data)
      
      setErro({})
      if (finish && response) {
        setStep('finish')
        cookies.set('cookiesProposalId', response?.data?.data?.proposalId)
      }
      setSimulator({
        ...response?.data?.data,
        ipca: response?.data?.data?.ipca || ''
      })
      setRecalculated(true)
    } catch (error) {
      if (!(error as any).response) {
        setErro({})
        console.warn(error)

        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Não foi possível se comunicar com o servidor! Tente novamente mais tarde!' 
        })
      } else if ((error as any).response.status === 422) {
        const invalidValues = interate.invalid((error as any)?.response?.data?.data?.invalid)
        setErro(invalidValues)
      }
    } finally {
      setDisableRecalculate(false)
      setLoadingButton(false)
      setLoading(false)
      setLoadingClient(false)
    }
  }

  const templateAreas = useBreakpointValue({
    lg: `
    'deadline          deadline          . aboutDeadline  aboutDeadline'
    '.                 .                 . .              .'
    'financing         financing         . aboutFinancing aboutFinancing' 
    '.                 .                 . .              .'
    'type              type              . .              .'
    '.                 .                 . .              .'
    'flatRate          flatRate          . .              .'
    'financialRegister financialRegister . .              .'
    'submit     .         done.              submitForm'`,
    md: `
    'deadline          deadline          aboutDeadline'
    '.                 .                 .'
    'financing         financing         aboutFinancing' 
    '.                 .                 .'
    'type              type              .'
    '.                 .                 .'
    ''flatRate         flatRate          .'
    'financialRegister financialRegister .'
    'submit     done       submitForm'`,
    base: `'financing' 
    'aboutFinancing'
    'deadline'
    'aboutDeadline'
    'type'
    'flatRate'
    'financialRegister'
    'submit'
    'done'
    'submitForm'`,
  })

  useEffect(() => {
    setFinancy(simulator.financing)
    setDeadline(simulator.deadline)
  }, [simulator.financing, simulator.deadline])

  useEffect(() => {
    setDisableRecalculate(false)
    setRecalculated(false)
  }, [deadline, financy, type])

  const handleShowParcels = () => setShowParcels(true)  

  const onClose = () => {
    setOpenConfirm(false)
    setErro({})
  }

  return (
    <>
    <Modal 
      isOpen={showParcels} 
      onClose={() => setShowParcels(false)}
      scrollBehavior='inside'
      size='full'
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader></ModalHeader>
        <ModalCloseButton />
        <ModalBody marginLeft='auto' marginRight='auto' className='table-div'>
          <Table>
            <thead className="table-content">
              {product.slug ? (                  
                <tr>                 
                  <th style={{ position: 'sticky', top: 0, background: theme.colors.primary[100] }}>Período</th>                                
                  <th style={{ position: 'sticky', top: 0, background: theme.colors.primary[100] }}>Vencimentos</th>                
                  <th style={{ position: 'sticky', top: 0, background: theme.colors.primary[100] }}>Saldo Devedor(base)</th>                
                  <th style={{ position: 'sticky', top: 0, background: theme.colors.primary[100] }}>Saldo Devedor(após pmt)</th>                
                  <th style={{ position: 'sticky', top: 0, background: theme.colors.primary[100] }}>Amortização</th>                
                  <th style={{ position: 'sticky', top: 0, background: theme.colors.primary[100] }}>Juros</th>                
                  <th style={{ position: 'sticky', top: 0, background: theme.colors.primary[100] }}>Parcela</th>                
                  <th style={{ position: 'sticky', top: 0, background: theme.colors.primary[100] }}>Prestamista</th>                
                  <th style={{ position: 'sticky', top: 0, background: theme.colors.primary[100] }}>DFI</th>                
                  <th style={{ position: 'sticky', top: 0, background: theme.colors.primary[100] }}>Parecela total</th>                
                </tr>
              ) : (
                <tr>
                  <th style={{ position: 'sticky', top: 0, background: theme.colors.primary[100] }}>Número</th>
                  <th style={{ position: 'sticky', top: 0, background: theme.colors.primary[100] }}>Amortização</th>
                  <th style={{ position: 'sticky', top: 0, background: theme.colors.primary[100] }}>Financiado</th>
                  <th style={{ position: 'sticky', top: 0, background: theme.colors.primary[100] }}>Juros</th>
                  <th style={{ position: 'sticky', top: 0, background: theme.colors.primary[100] }}>Total à ser pago</th>
                  <th style={{ position: 'sticky', top: 0, background: theme.colors.primary[100] }}>Parcela</th>
                  <th style={{ position: 'sticky', top: 0, background: theme.colors.primary[100] }}>Próxima parcela</th> 
                </tr>
              )}                
            </thead>
            <tbody className="table-content">
              {simulator.paymentParcels.map((parcel, index) => (
                <Fragment>
                  {product.slug ? (
                    <tr key={index}>
                      <td>{parcel.parcelIndex}</td>
                      <td>{(dayjs(parcel.parcelDate).format('DD/MM/YYYY'))}</td>
                      <td>{format.toReal(parcel.outstanding)}</td>
                      <td>{format.toReal(parcel.outstandingAfterPmt)}</td>
                      <td>{format.toReal(parcel.amortization)}</td>                                    
                      <td>{format.toReal(parcel.interest)}</td>
                      <td>{format.toReal(parcel.parcelValue)}</td>                  
                      <td>{format.toReal(parcel.prestamista)}</td>
                      <td>{format.toReal(parcel.dfi)}</td>
                      <td>{format.toReal(parcel.totalValue)}</td>
                    </tr>  
                  ): (
                    <tr>
                      <td>{parcel.parcel}</td>
                      <td>{format.toReal(parcel.amortization)}</td>
                      <td>{format.toReal(parcel.debt)}</td>
                      <td>{format.toReal(parcel.interest)}</td>
                      <td>{format.toReal(parcel.totalDebt)}</td>
                      <td>{format.toReal(parcel.parcelValue)}</td>
                      <td>{format.toReal(parcel.nextDebt)}</td> 
                    </tr>
                  )}
                </Fragment>
              ))
            }
            </tbody>
          </Table>
        </ModalBody>
      </ModalContent>
    </Modal>

    <Modal isOpen={openConfirm} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirmação de e-mail</ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            <Box display={'flex'}  flexWrap={'wrap'} justifyContent={'space-between'}>
              <Text flex={"1 2 300px"} fontSize=".75rem" fontWeight="400">Atenção: Será enviado um e-mail com o acesso para que você possa acompanhar o andamento da sua proposta. Por favor confirme seu e-mail.</Text> 
            </Box>
            
            <TextInput name="confirmEmail" type='email' onFocus={() => setError({})} onChange={(e) => setConfirmEmail(e.target.value)} value={confirmEmail || form.confirmEmail} autoFocus={true} defaultValue={form.confirmEmail} error={errors.email} />
  
            {
              (errors.email && form.confirmEmail && (form.confirmEmail !== form.email)) && 
              (
                <>
                  <Box display={'flex'}  flexWrap={'wrap'}>
                    <Text flex={"1 2 300px"} fontSize="1rem" fontWeight="400">Deseja utilizar o e-mail:</Text> 
                    <RadioGroup onChange={(e)=> {setConfirmEmail(String(e)); setChangeEmail(String(e) === form.confirmEmail)}}>
                        <Radio w="100%" colorScheme="secondary" value={form.email}>{form.email}</Radio>
                        <Radio w="100%"colorScheme="secondary" value={form.confirmEmail}>{form.confirmEmail}</Radio>
                    </RadioGroup>
                  </Box>
                </>
              )
            }
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Cancelar
            </Button>
            <Button isLoading={loading} disabled={simulator.sentToIndiky} colorScheme="secondary" variant="solid" onClick={()=> !simulator.sentToIndiky && newSimulation(true) }>Enviar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: 'auto', marginTop: '20px' }}>
      <Grid
        gridGap="2"
        marginBottom="20px"
        gridTemplateAreas={templateAreas}
      >
        <GridItem gridArea="financing">
          <Text fontWeight="500">{layout.text.financy}:</Text>
          <InputMoney
            loading={!simulator.financing}
            name="income"
            defaultValue={ Number((simulator.financing || 0).toFixed(2)) || simulator.financing}
            onChange={(event) => {setFinancy(Number(event))}}
            error={errors.financing}
          />
        </GridItem>

        <GridItem gridArea="aboutFinancing" display='flex' flexDirection='column' alignItems='flex-start' justifyContent='flex-end'>
          <Text fontWeight="500">Valor mínimo: {format.toReal(simulator.min)}</Text>
          <Text fontWeight="500">Valor máximo: {format.toReal(simulator.max)}</Text>
        </GridItem>

        <GridItem gridArea="deadline" className="sm-mgTp">
          <Text fontWeight="500">Prazo (em meses):</Text>
          <TextInput 
            name="deadline" 
            value={deadline}
            onChange={(event) => setDeadline(event.target.value)} 
            error={errors.deadline} 
          />
        </GridItem>

        <GridItem gridArea="aboutDeadline" display='flex' flexDirection='column' alignItems='flex-start' justifyContent='flex-end'>
          <Text fontWeight="500">Prazo mínimo: {simulator.deadlineMin}</Text>
          <Text fontWeight="500">Prazo máximo: {simulator.deadlineMax}</Text>
        </GridItem>

        <GridItem gridArea="type">
          <Text fontWeight="500">Sistema de Amortização:</Text>
          <Select
            name="type"
            options={
              [{ value: 'sac', label: 'SAC' },
              { value: 'price', label: 'PRICE' }]}
            error={errors.type}
            defaultValue={type}
            onChange={(event) => setType(event.target.value)}
          />
        </GridItem>

        {deadline <= 60 && (<GridItem gridArea="flatRate" display='flex' flexDirection='column' alignItems='flex-start'>
          <Text fontWeight="500">Taxa fixa:</Text>           
          <Switch           
            onChange={((event: ChangeEvent<HTMLInputElement>) => setFlatRate(event.target.checked))}
            name="flatRate" 
          />
        </GridItem>)}

        <GridItem gridArea="financialRegister" display='flex' flexDirection='column' alignItems='flex-start'>
          <Text fontWeight="500">Despesas cartoriais:</Text>        
          <Switch  
            onChange={((event: ChangeEvent<HTMLInputElement>) => setFinancialRegister(event.target.checked))}         
            name="financialRegister" 
          />
        </GridItem>

        {
          layout.fullPage 
            ? (<>
                <GridItem gridArea="submit" >
                  <Button
                    colorScheme="secondary"
                    variant="solid"
                    type="button"
                    onClick={() => newSimulation(false)}
                    disabled={disableRecalculate}
                    width='100%'
                  >
                    {
                      loadingButton ?
                        (<LoopIcon className="rotate-center" />)
                        : "Recalcular"
                    }
                  </Button>
                </GridItem>

                <GridItem gridArea="done" display={recalculated ? 'flex' : 'none'} alignItems="center" flexDirection="column" justifyContent="center">
                  <Text textColor="Green">Recalculado com sucesso</Text>
                  {simulator.ipca && (                    
                    <Text>Correção monetária - {simulator.ipca}</Text>
                  )}
                </GridItem>

                <GridItem gridArea="submitForm" display="flex" alignItems="center">
                  <Button colorScheme="secondary" variant="solid" disabled={simulator.sentToIndiky} onClick={() => setOpenConfirm(true)} gridArea="submit" width='100%'>
                    {
                      line.internal ? 
                        loadingClient ? (<LoopIcon className="rotate-center" />) 
                        : 'Contratar simulação' 
                      : 'Quero contratar'
                    }
                  </Button>
                </GridItem>
              </>) 
            : (<>
                <GridItem gridArea="submit" >
                  <Button
                    colorScheme="secondary"
                    variant="solid"
                    type="button"
                    onClick={() => newSimulation(false)}
                    disabled={disableRecalculate}
                  >
                    {
                      loadingButton ?
                        (<LoopIcon className="rotate-center" />)
                        : "Recalcular"
                    }
                  </Button>
                </GridItem>

                <GridItem gridArea="done" display="flex" alignItems="center">
                  <Text textColor="Green">{recalculated ? "Recalculado com sucesso" : ""}</Text>
                </GridItem>
              </>)
        }

      </Grid>
      <Table title='Aquisição PF - SFH'>
        <tr>
          <td>Valor do imóvel</td>
          <td width="30%">{format.toReal(form.value)}</td>
        </tr>
        <tr>
          <td>(-) Entrada</td>
          <td width="30%">{format.toReal(simulator.entryValue)}</td>
        </tr>
        <tr>
          <td>(=) {layout.text.financy}</td>
          <td width="30%">{format.toReal(simulator.financing)}</td>
        </tr>
      </Table>
      <Table title='Parcelas'>
        <tr>
          <td>Prazo (meses)</td>
          <td width="30%">{simulator.deadline}</td>
        </tr>
        <tr>
          <td>Sistema de Amortização</td>
          <td width="30%">{simulator.type.toUpperCase()}</td>
        </tr>

        <tr>
          <td>Primeira parcela</td>
          <td width="30%">{format.toReal(simulator.paymentParcels[0]?.totalValue || simulator.paymentParcels[0]?.parcelValue)}</td>
        </tr>
        <tr>
          <td>Última parcela</td>
          <td width="30%">{format.toReal(simulator.paymentParcels[simulator.paymentParcels.length - 1]?.totalValue || simulator.paymentParcels[simulator.paymentParcels.length - 1]?.parcelValue)}</td>
        </tr>
        <tr>
          <td>Parcelas</td>
          <td width="30">
            <p className="p-link" onClick={handleShowParcels}>Ver todas as parcelas</p>
          </td>
        </tr>
      </Table>
      <Table title='Juros'>
        <tr>
          <td>Taxa de juros anual (CET - Custo Efetivo Total)</td>
          <td width="30%">{format.toPercentage(simulator.anualTax)}</td>
        </tr>
        <tr>
          <td>Taxa de juros mensal</td>
          <td width="30%">{format.toPercentage(simulator.monthlyTax)}</td>
        </tr>
        {
          simulator.iof ? (
            <tr>
              <td>IOF</td>
              <td width="30%">{format.toPercentage(simulator.iof)}</td>
            </tr>
          ) : (<></>)
        }
        {
          simulator.extraTax ? (
            <tr>
              <td>Outras taxas</td>
              <td width="30%">{format.toPercentage(simulator.extraTax)}</td>
            </tr>
          ) : (<></>)
        }
      </Table>

      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: '20px', marginBottom: '20px' }} >
        <Button colorScheme="secondary" variant="solid" type="button" gridArea="goBack" onClick={() => setBeforeStep()}>
          Voltar
        </Button>
        <Button colorScheme="secondary" disabled={simulator.sentToIndiky} variant="solid" onClick={() => setOpenConfirm(true)} gridArea="submit">
          Quero contratar
        </Button>
      </div>

    </div>
    </>
  )
}

export default connector(Result)