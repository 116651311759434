export const SET_LINE = 'SET_LINE'

export interface ILine {
  internal?: boolean
  clientId?: string
  productId?: string
  simulationId?: string
  token?: string
}

interface setLine {
  type: typeof SET_LINE
  payload: ILine
}

export type LineTypes = setLine 