import format from './format'
import { IForm } from '../store/modules/form/types'
import { IVehicle } from '../store/modules/vehicle/types'



const interate = {
  body: (data: IForm | IVehicle) => {
    const formatedObj: any = {}
    for (let [key, value] of Object.entries(data)) {
      formatedObj[key] = format.formatBody(key, value)
    }
    return formatedObj
  },

  invalid: (data: { field: string, message: string }[]) => {
    const formatedObj: any = {}
    for (let obj of data) {
      formatedObj[obj.field] = obj.message
    }
    return formatedObj
  }

}


export default interate