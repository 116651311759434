import React from 'react'
import { IIconProps } from "./Icons"

function Dot (props: IIconProps) {
  const { height, width, fill} = props
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={height || "24px"} viewBox="0 0 24 24" width={width || "24px"} fill={fill || "#000000"}>
      <path d="M24 24H0V0h24v24z" fill="none"/>
      <circle cx="12" cy="12" r="8"/>
    </svg>
  )
}

export default Dot
