export const getCookies = () =>{
  let cookies = {}

  document.cookie?.split('; ').forEach(item => {
    if (!item) return
    let [key, value] = item.split('=')
    if (key.startsWith('?'))
      key = key.split('?')[1]
    Object.assign(cookies, { [key]: value })
  })

  return cookies
}