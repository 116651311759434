import { combineReducers } from 'redux'
import { connectRouter, LocationChangeAction, RouterState } from 'connected-react-router'
import { Reducer } from 'react'
import form from './form/reducer'
import step from './step/reducer'
import error from './error/reducer'
import simulator from './simulator/reducer'
import vehicle from './vehicle/reducer'
import line from './line/reducer'
import product from './product/reducer'
import global from './global/reducer'

import { Form } from './form/types'
import { Step } from './step/types'
import { IError } from './error/types'
import { ISimulator } from './simulator/types'
import { Vehicle } from './vehicle/types'
import { ILine } from './line/types'
import { IProduct } from './product/types'
import { IGlobal } from './global/types'

const createRootReducer = (history: any) => combineReducers({
  router: connectRouter(history),
  form,
  step,
  error,
  simulator,
  vehicle,
  line,
  product,
  global
})

export interface RootState {
  router: Reducer<RouterState<any>, LocationChangeAction<any>>
  form: Form
  step: Step
  error: IError
  simulator: ISimulator
  vehicle: Vehicle
  line: ILine,
  product: IProduct
  global: IGlobal

}

export default createRootReducer
