import React, { ReactElement, useEffect, useState } from 'react'
import { Box, Flex, Grid, GridItem, useBreakpointValue } from "@chakra-ui/react"
import { Content, Wrapper } from '../../assets/styles/styled'
import SimulationPageHeader from '../../components/SimulationPageHeader'
import { layout } from '../../config/projectStyle'
import theme from 'assets/styles/theme'

interface Props {
  children: ReactElement | string
}

const Simulation = ({ children }: Props) => {

  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window
    return {
      width,
      height
    }
  }

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const templateColumns = useBreakpointValue({
    xl: "auto 55vw auto",
    lg: "auto 60vw auto",
    md: "auto 70vw auto",
    sm: "auto 95vw auto",
    base: "auto 95vw auto"
  })

  const templateAreas = useBreakpointValue({
    lg: `'header header header'
    '. content .'
    'footer footer footer'`,
    md: `'header header header'
    '. content .'
    'footer footer footer'`,
    sm: `'header header header'
    'content content content'
    'footer footer footer'`,
    base: `'header header header'
    'content content content'
    'footer footer footer'`
  })

  const header = useBreakpointValue({
    xl: "55vw",
    lg: "60vw",
    md: "70vw",
    sm: "95vw",
  })

  return (
    <>
      {
        layout?.fullPage ? (
          <Content>
            <Box bg="primary.50" borderBottomRadius="10px" marginBottom='2' borderTopColor="secondary.400" borderTopStyle="solid" borderTopWidth="2vh" p="4" paddingBottom="2" height="100vh">
              {children}
            </Box>
          </Content>
        ) : (
            <>
              <Box w="100%" h="40vh" bg={theme.colors.primary['900']} position="absolute" zIndex="-1" />
              <Grid templateAreas={templateAreas} templateColumns={templateColumns} templateRows="20vh 1fr auto" height={windowDimensions.height}>
                <Flex justifyItems="center" gridArea="header" alignItems="center" justifyContent="center" h='100%'>
                  <Box width={header} h="100%">
                    <SimulationPageHeader />
                  </Box>
                </Flex>
                <GridItem gridArea="content">
                  <Wrapper>
                    <Content>
                      {/* body */}
                      <Box bg="primary.50" borderBottomRadius="10px" marginBottom='2' borderTopColor="secondary.400" borderTopStyle="solid" borderTopWidth="2vh" borderTopRadius="10px" p="4" paddingBottom="2">
                        {children}
                      </Box>
                    </Content>
                  </Wrapper>
                </GridItem>
                <GridItem gridArea="footer">
                  <Box as="hr" w="100%" h="4vh" bg={theme.colors.primary['900']} />
                </GridItem>
              </Grid>
            </>
          )
      }

    </>
  )
}

export default Simulation