import React, { useState, MutableRefObject, useEffect } from 'react'
import { toCurrency } from '../../../utils/digits'
import { InputMask } from '../../../types'
import { InputStyle } from './styled'
import theme from '../../../assets/styles/theme'
import { InputGroup, InputLeftAddon, InputRightAddon } from '@chakra-ui/react'

const colors = theme.colors

interface Props extends React.InputHTMLAttributes<any> {
  separator?: ',' | '.'
  mask?: InputMask
  defaultValue?: number
  leftAddon?: string
  rightAddon?: string
  error?: string
  ref?: MutableRefObject<any>
  isInvalid?: boolean
  errorBorderColor?: string
  focusBorderColor?: string
}

const NumberInput = React.forwardRef((
  {
    name,
    focusBorderColor = colors.secondary["400"],
    errorBorderColor,
    onChange,
    separator,
    defaultValue,
    error,
    leftAddon, 
    rightAddon,
    ...rest
  }: Props,
  ref?: MutableRefObject<any> | ((instance: any) => void) | null) => {

  const [value, setValueInput] = useState(toCurrency(defaultValue || 0, separator))
  const handleChange = (event: any) => {
    
    const valueAsCurrency = toCurrency(event.target.value, separator)
    event.target.value = valueAsCurrency

    setValueInput(valueAsCurrency) 
    if (onChange) {
      event.persist()
      
      onChange(event)
    }
  }

  useEffect(() => {
    const valueAsCurrency = toCurrency(defaultValue || 0, separator)
    setValueInput(valueAsCurrency) 

  }, [defaultValue, separator])

  return (
    <>
    <InputGroup size={"sm"} >
      {leftAddon &&
        <InputLeftAddon children="R$" />
      }
      <InputStyle focusBorderColor={focusBorderColor} style={error?{border:`${errorBorderColor} solid 1px`}:{}}>
        <input
          type="number"
          pattern="\d*"
          step="any"
          ref={ref}
          {...rest}
          value={value}
          onChange={handleChange}
        />
      </InputStyle>
      {rightAddon &&
        <InputRightAddon children={rightAddon} />
      }
    </InputGroup>
    {error && <span style={{ color: '#f00', fontSize: '0.8em' }}>{error}</span>}
    </>
  )
})

export default NumberInput