import { useSelector } from 'react-redux'
import { RootState } from '../store/modules/rootReducer'

const useForm = () => {
  const form = useSelector((state: RootState) => state.form)
  return form
}

const useError = () => {
  const error = useSelector((state: RootState) => state.error)
  return error
}

const useSimulator = () =>{
  const simulator = useSelector((state: RootState) => state.simulator)
  return simulator
}


export {
  useForm,
  useError,
  useSimulator
}