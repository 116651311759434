import styled from "@emotion/styled"
import theme from "../../assets/styles/theme"

const TITLE_SIZE = "16px"
const BODY_SIZE = "14px"
const DEFAULT_BG = theme.colors.primary['100'] 
const DEFAULT_COLOR = theme.colors.secondary['400']
const WHITE_BG = theme.colors.primary['10'] 
const BORDER_COLOR = theme.colors.primary['100']

export const TableWarper = styled.table`
  width: 100%;
  color: #747373;

  thead {
    font-size: ${TITLE_SIZE};
    background: ${DEFAULT_BG};
    color: ${DEFAULT_COLOR};
    border-bottom-width: 1px;
    padding: 10px;
    
    th {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      padding: 10px;
      text-align: left;
      span {
        margin-bottom: 5px;
      }
    }
  }

  tbody {
    font-size: ${BODY_SIZE};
    /* padding: 10px; */

    td {
      padding: 10px;
      border: 1px solid ${BORDER_COLOR};
      background-color: ${WHITE_BG};
    }
  }
`