import axios from 'axios'
import variables from '../config/variables'

let mainUrl = variables.env === 'production' ? process.env.REACT_APP_MAIN_SERVER : variables.env === 'development' ? process.env.REACT_APP_MAIN_SERVER : variables.mainServer

const mainApi = axios.create({
  baseURL: mainUrl,
  timeout: 40000
});

mainApi.defaults.headers.post["Content-Type"] = "application/json";

const getToken = () => {
  const token = variables.mainToken
  if(token && token !== null) return `Bearer ${token}`
  return undefined
}

mainApi.interceptors.request.use(config => {

  const token = getToken()
  if(
    (!config.url?.endsWith('login') ||
    !config.url?.endsWith('refresh') ||
    !config.url?.endsWith('signup') ) && token
  ){
    config.headers.Authorization = token
  }
  return config
}, error => Promise.reject(error))

export default mainApi;