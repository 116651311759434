import { LineTypes, ILine } from './types'

const _initialState: ILine = {
  internal: false,
  clientId: '',
  productId: '',
  token: '',
}

const resources = (state = _initialState, action: LineTypes ) => {
  switch (action.type) {
    //Handler de actions, responsável por setar o state.

    case 'SET_LINE': {
      return {
        ...state,
        ...action.payload
      }
    }

    default:
      return state
  }
}

export default resources