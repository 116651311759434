import React from 'react'
import { 
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Box
} from "@chakra-ui/react"

interface IModalIncomeProps {
  isOpen: boolean
  termsText: React.ReactElement | string
  title: React.ReactElement | string
  onClose: ()=>void
}

function ModalTerms({isOpen, onClose, termsText, title }:IModalIncomeProps) {
  return (
    <Modal 
    isOpen={isOpen} 
    onClose={onClose}
  >
    <ModalOverlay />
    <ModalContent className="modal-terms__content">
      <ModalHeader>{title}</ModalHeader>
      <ModalCloseButton />
      <ModalBody marginLeft='auto' marginRight='auto'>
        <Box display="flex" alignItems="center" flexDirection="column"> 
            {termsText}
        </Box>
      </ModalBody>
    </ModalContent>
  </Modal>
  )
}

export default ModalTerms
