import {createStore, applyMiddleware, compose} from 'redux'
import { routerMiddleware } from 'connected-react-router'
import createSagaMiddleware from 'redux-saga'
import createRootReducer from './modules/rootReducer'
import { createBrowserHistory } from 'history'
import rootSaga from './modules/rootSaga'


export const history = createBrowserHistory()

const sagaMiddleware = createSagaMiddleware()

const composeEnhancers = (
  typeof window !== 'undefined' && 
  (window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__' as any] as any) && 
  (window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__' as any] as any)({ trace: true, traceLimit: 25 })
) || compose

const store = createStore(createRootReducer(history), composeEnhancers(applyMiddleware(routerMiddleware(history), sagaMiddleware)))

sagaMiddleware.run(rootSaga)

export default store
