const DO_NOT_UPPERCASE = ['de', 'das', 'dos', 'do', 'da']

const cpfMask = (value: string) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')
}

const cnpjMask = (value: string) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')
}

const phoneMask = (value: string, raw = false) => {
  const onlyDigits = value.replace(/\D/g, '')
  if (onlyDigits.length > 10) return cellMask(value)
  return onlyDigits
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(-\d{4})\d+?$/, '$1')
}

const cellMask = (value: string) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{5})(\d)/, '$1-$2')
    .replace(/(-\d{4})\d+?$/, '$1')
}

const rgMask = (value?: string) => {
  if(!value) return ''
  return value
    .replace(/[^a-z\d]/ig, '')
    .replace(/([a-z\d]{2})([a-z\d])/i, '$1.$2')
    .replace(/([a-z\d]{3})([a-z\d])/i, '$1.$2')
    .replace(/([a-z\d]{3})([a-z\d])/i, '$1-$2')
    .replace(/(-[a-z\d]{2})[a-z\d]+?$/i, '$1')
    .toUpperCase()
}

const rneMask = (value?: string) => {
  if(!value) return ''
  return value
    .replace(/[^a-z\d]/ig, '')
    .replace(/([a-z\d]{7})([a-z\d])/i, '$1-$2')
    .replace(/(-[a-z\d]{1})([a-z\d])+?$/i, '$1')
    .toUpperCase()
}

const nameMask = (value: string) => {
  if(!value || !value.trim()) return ""
  const onlyText = value.replace(/[^a-záàâãéèêíïóôõöúüçñ ']/ig, '')
  return onlyText
    .split(' ')
    .map(w => {
      if(w.length >= 1){
        if(!DO_NOT_UPPERCASE.includes(w.toLowerCase()))
          return w[0].toUpperCase() + w.toLowerCase().substr(1)
        return w.toLowerCase()
      }
      return ' '
    }).join(' ')
}

const moneyMask = (value: string) => {
  return value
    // .replace(/\D/g, '')
    // .replace(/(^\d*(,|\.)\d{2})/, '$1')
    .replace(/(^\d*(,|\.)?\d{0,2})/, "$1")
}

const dateMask = (value?: string) => {
  if (!value) return ''
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\/\d{4})\d+?$/, '$1')
}

const mask = (value: string, mask?: string, raw?:boolean): string => {
  if(!mask) return value
  switch(mask.toLowerCase()){
    case 'cpf':
      return cpfMask(value)
    case 'cnpj':
      return cnpjMask(value)
    case 'phone':
      return phoneMask(value, raw)
    case 'cell':
      return cellMask(value)
    case 'rg':
      return rgMask(value)
    case 'rne':
      return rneMask(value)
    case 'name':
      return nameMask(value)
    case 'money':
      return moneyMask(value)
    case 'date': 
      return dateMask(value)
    default: 
      return value
  }
}

export default mask