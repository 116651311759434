import { SET_STEP } from './types'

export function setStep (step: { active: string }) {
  return {
    type: SET_STEP,
    payload: step
  }
}

export function setBeforeStep () {
  return {
    type: 'SET_PAST_STEPS'
  }
}