import {isIOS, isMobileSafari} from 'react-device-detect'

const goToLink = (link: string) => {
  let a = document.createElement('a')
  a.href = link
  if(!isIOS && !isMobileSafari)
    a.target = "_blank"
  a.click()
}

export {goToLink}