export const SET_SIMULATOR = 'SET_SIMULATOR'
export const SET_SIMULATOR_POST = 'SET_SIMULATOR_POST'

export interface IParcel {
  parcelIndex: number
  parcelDate: string
  outstanding: number
  outstandingAfterPmt: number
  value: number
  totalValue: number
  prestamista: number
  dfi: number

  parcel: number
  debt: number
  interest: number
  totalDebt: number
  amortization: number
  parcelValue: number
  nextDebt: number
}

export interface ISimulatorSet {
  _id?: string
  number: number
  paymentParcels: IParcel[]
  totalInterest: number
  totalValue: number
  totalAmortization: number
  financing: number
  deadline: number
  anualTax: number
  monthlyTax: number
  entryValue: number
  maxParcelValue: number
  minimunIncome: number
  min: number
  max: number
  iof?: number
  extraTax?: number
  deadlineMin: number
  deadlineMax: number
}

export interface ISimulator {
  _id?: string
  entryId: string
  type: string
  number: number
  paymentParcels: IParcel[]
  totalInterest: number
  totalValue: number
  totalAmortization: number
  financing: number
  deadline: number
  anualTax: number
  monthlyTax: number
  entryValue: number
  maxParcelValue: number
  minimunIncome: number
  finish?: boolean
  min: number
  max: number
  iof?: number
  extraTax?: number
  deadlineMin: number
  deadlineMax: number
  ipca: string
  sentToIndiky?: boolean
}
export interface ISimulatorPost {
  entryId: string
  type?: string
  financing?: number
  deadline?: number
  finish?: boolean
}

interface setSimulator {
  type: typeof SET_SIMULATOR
  payload: ISimulatorSet
}

interface setSimulatorPost {
  type: typeof SET_SIMULATOR_POST
  payload: ISimulatorPost
}

export type SimulatorTypes = setSimulator | setSimulatorPost