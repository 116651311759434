import React, { useRef, useCallback, useEffect, useLayoutEffect, useState } from "react";
import { connect, ConnectedProps } from 'react-redux'
import { Dispatch } from "redux";

import queryString from 'query-string'

import StepIndicator from "../components/StepIndicator";
import { Initial, Financy, AboutEntry, Location, Fgts, Result } from './steps'

import { RootState } from "../store/modules/rootReducer";
import { setStep, setBeforeStep } from "../store/modules/step/actions";
import { setForm } from "../store/modules/form/actions";
import { setError } from '../store/modules/error/actions'
import { setSimulator, setSimulatorPost } from '../store/modules/simulator/actions'
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import interate from '../utils/interate'
import mainApi from '../services/mainApi'

import { IForm } from "../store/modules/form/types";
import { ISimulatorPost, ISimulatorSet } from '../store/modules/simulator/types' 
import { IError } from '../store/modules/error/types'
import { layout } from "../config/projectStyle";
import { setProduct } from "../store/modules/product/actions";
import { IProduct } from "../store/modules/product/types";
import FinishPage from "./steps/FinishPage";
import format from "../utils/format";
import ErrorPage from "./steps/ErrorPage";
import { useHistory } from "react-router-dom";
import Cookies from 'universal-cookie'
import Swal from "sweetalert2";

const mapState = (state: RootState) => {
  return {
    step: state.step.active,
    form: state.form,
    simulator: state.simulator,
    product: state.product,
    errors: state.error
  }
}

const mapDispatch = (dispatch: Dispatch) => ({
  setForm: async (form: IForm) => await dispatch(setForm(form)),
  setStep: (step: string) => dispatch(setStep({ active: step })),
  setErro: (errors: IError) => dispatch(setError(errors)),
  setBeforeStep: () => dispatch(setBeforeStep()),
  setSimulatorPost: (simulator: ISimulatorPost) => dispatch(setSimulatorPost(simulator)),
  setSimulator: (simulator: ISimulatorSet) => dispatch(setSimulator(simulator)),
  setProductSlug: (product: IProduct) => dispatch(setProduct(product))  
})

type PropsFromRedux = ConnectedProps<typeof connector>

const connector = connect(mapState, mapDispatch)

const Simulator = ({ step, form, simulator, product, setStep, setErro, errors, setBeforeStep, setForm, setSimulatorPost, setSimulator, setProductSlug }: PropsFromRedux) => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState<boolean>(false)
  const history = useHistory()
  const cookies = new Cookies()
  const search = queryString.parse(history.location.search)
  
  const stepRouter = () => {
   
    if(!search?.agx_hash) return <ErrorPage />
    if(layout.fullPage) {
      if(layout.oneStep) return <Initial errors={errors} loading={loading}/>
      else {
        switch(step) {
          case 'initial': return <Initial errors={errors} loading={loading}/>
          case 'financy': return <Financy />
          case 'result': return <Result setStep={setStep} />
        }
      }

    } else {

      switch (step) {
        case 'financy': return <Financy />
        case 'aboutentry': return <AboutEntry setStep={setStep}/>
        case 'location': return <Location />
        case 'fgts': return <Fgts />
        case 'result': return <Result setStep={setStep} />
        case 'finish': return <FinishPage setStep={setStep}/>
        case 'error': return <ErrorPage/>
      }
      
    }
  }

  useLayoutEffect(() => {
    if(layout.fullPage) {

      if(layout.oneStep) setProductSlug({ slug: 'precatorio', sub: '' })
      else setProductSlug({ slug: 'home-equity', sub: 'home' })
    } else setProductSlug({ slug: '', sub: '' })
      
    try{
      const cookiesProposalId = cookies.get('cookiesProposalId')
      if (cookiesProposalId){ 
        Swal.fire({
          icon: 'info',
          title: 'Atenção',
          text: 'Notamos que você possui uma proposta em andamento, siga o tutorial e saiba como prosseguir.' 
        })
        setStep('finish')
      }
    }catch (err){
      console.log(err)
    }
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const  handleReset = (data: any) => setBeforeStep()

  const handleSubmit = async (data: any) => {
    if (data.value) data.value = format.formatMoneySend(data.value)
    if (data.income) data.income = format.formatMoneySend(data.income)
    if (data.rne) data.rne = format.formatSendIdDocument(data.rne)
    if (data.rg) data.rg = format.formatSendIdDocument(data.rg)

    if (data.rg) data.rne = undefined
    if (data.rne) data.rg = undefined

    const formatedBody = interate.body(data)

    let newForm = { ...form, ...formatedBody, hasSimulator: search?.hasSimulator === "true" || !search?.hasSimulator}               
    setLoading(true)
    try {
      let productBody = product.slug ? { product: product.slug } : {}
      const response = await mainApi.post('/entries', { step, datas: newForm, ...productBody })
      
      const data: any = response.data.data
      const { firstStepId, secondStepId, thirdStepId } = data
      newForm = {...newForm, firstStepId, secondStepId, thirdStepId}
      
      if (data?.entry?._id) {
        setSimulatorPost({ entryId: data.entry._id })
      }      
            
      if(data?.body?.datas?.product) {        
        setProductSlug({ ...product, sub: data.body.datas.product })
      }

      if(!layout.oneStep && (search?.hasSimulator === "true" || !search?.hasSimulator)) setStep(data.nextStep)
      setForm(newForm)
      setErro({})

    } catch (error) {
      if (!(error as any).response) {
        setErro({})
        console.warn(error)

        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Não foi possível se comunicar com o servidor! Tente novamente mais tarde!' 
        })
      } else if ((error as any).response.status === 422) {
        const invalidValues = interate.invalid((error as any)?.response?.data?.data?.invalid)        
        setErro(invalidValues)
      }
    } finally {
      setLoading(false)
    }
  }

  const test = useCallback(async () => {
    if (!!simulator.entryId) {
      let productBody = product.slug ? { product: product.slug } : {}
      try {
        const response = await mainApi.post(`/simulation/${simulator.entryId}/${simulator.type}`, productBody)
        
        const data: ISimulatorSet = response.data.data
        
        setSimulator(data)
        setErro({})
        
      } catch (error) {
        if (!(error as any).response) {
          setErro({})
          console.warn(error)
  
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Não foi possível se comunicar com o servidor! Tente novamente mais tarde!' 
          })
        } else if ((error as any).response.status === 422) {
          const invalidValues = interate.invalid((error as any)?.response?.data?.data?.invalid)
          setErro(invalidValues)
        }
      }
    }
  }, [simulator.entryId]) // eslint-disable-line 

  useEffect(() => {
    test()
  }, [simulator.entryId, test])
  
  return (
    <>
      <StepIndicator
        steps={[
          { index: 1, title: 'Dados pessoais', value: 'aboutentry' },
          { index: 2, title: 'Localização', value: 'location' },
          { index: 3, title: 'FGTS', value: 'fgts' },
          { index: 4, title: 'Simulação', value: 'result'}
        ]}
        activeStep={search?.agx_hash ? step : ''} 
      />
      <Form
        ref={formRef}
        initialData={form}
        onSubmit={handleSubmit}
        onReset={handleReset}
      >
        { product.slug === undefined ? (<></>) : stepRouter() }
      </Form>
    </>
  );
};


export default connector(Simulator);