import React, { ReactChild, useEffect, useRef } from "react";

import { useField } from "@unform/core";
import { Checkbox, CheckboxProps } from "@chakra-ui/react";

interface Props extends Omit<CheckboxProps, "name"> {
  name: string
  error?: string
  defaultValue?: string
  children?: ReactChild
}

export default function Check({
  name,
  defaultValue,
  error,
  children,
  ...rest
}: Props) {
  const inputRef = useRef<any>(null);

  const { fieldName, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,

      ref: inputRef.current,

      path: "checked",
    });
  }, [fieldName, registerField]);


  return (
    <>
      <Checkbox
        isInvalid={Boolean(error && error.trim() !== "")}
        defaultValue={defaultValue}
        ref={inputRef}
        name={fieldName}
        error={error}
        {...rest}
      >
        {children}
      </Checkbox>
      <div className="checkbox-error">{error ? error : ''}</div>
    </>
  );
}
