import { SelectProps } from '@chakra-ui/react'
import React, { MutableRefObject, ReactElement } from 'react'
import {Select as ChakraSelect} from "@chakra-ui/react"
import theme from '../../assets/styles/theme'

const colors = theme.colors

interface Option {
  label: ReactElement | string;
  value?: string;
  error?: string
}

export interface CustomSelectProps extends SelectProps{
  options: Option[]
  error?: string
}


const Select = React.forwardRef(
  ({ size = 'sm', focusBorderColor = colors.secondary['400'], defaultValue, error, options, ...rest}: CustomSelectProps, ref?: MutableRefObject<any> | ((instance: any) => void) | null) =>  {
  return (
    <>
      <ChakraSelect {...rest} value={defaultValue} ref={ref} size={size} focusBorderColor={focusBorderColor} bg={colors.primary['10']}>
        {options.map(op => <option key={op.value} value={op.value}>{op.label}</option>)}
      </ChakraSelect>
      {error && <span style={{ color: '#f00', fontSize: '0.8em' }}>{error}</span>}
    </>
  )
})

export default Select
