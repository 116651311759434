import { getAllStates, getStateCities as getCities } from "easy-location-br";
import { Option } from "../types";

export const getStates = (): Option[] => {
  const states = getAllStates()

  return states.map(state => ({
    label: state.id,
    value: state.id.toLowerCase()
  }))
}

export const getStateCities = (state: string): Option[] => {
  const cities = getCities(state.toUpperCase())

  return cities.map(city => ({
    label: city.name,
    value: city.name
  }))
}
