import React, { ReactElement, useEffect, useRef } from "react";

import { useField } from "@unform/core";
import { RadioProps, Stack } from "@chakra-ui/react";
import {RadioWrapper} from './styled'

interface Option {
  label: ReactElement | string;
  value?: string | number
}

interface Props extends Omit<RadioProps, "name"> {
  name: string
  options: Array<Option>
  error?: string
  defaultValue?: string
}

export default function FormInput({ name, options, error, defaultValue, onChange, ...rest }: Props) {
  const inputRefs = useRef<HTMLInputElement[]>([]);

  const { fieldName, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,

      ref: inputRefs.current,

      getValue: (refs: HTMLInputElement[]) => {
        const foundRef =  refs.find((ref) => ref.checked)
        return foundRef ? foundRef.value : null;
      },

      setValue(refs: HTMLInputElement[], value) {
        const item = refs.find(ref => ref.value === value);

        if (item) {
          item.checked = true;
        }
      }

    });
  }, [fieldName, registerField]);

  function addRefItem(ref: any) {
    if (ref != null && !!ref && !inputRefs.current.includes(ref)) {
      inputRefs.current.push(ref);
    }
  }

  return (
    <>
      <Stack direction="row" spacing='4'>
        {options.map((op, index) => (
          <RadioWrapper key={index}>
            <input
              ref={addRefItem}
              type="radio"
              name={fieldName}
              value={op.value}
              id={`${fieldName}-${op.value}`} 
              defaultChecked={defaultValue === op.value}
              onChange={onChange}
            />
            <label htmlFor={`${fieldName}-${op.value}`}>
              {op.label}
            </label>
        </RadioWrapper>
        ))}
      </Stack>
      {error && <span style={{ color: '#f00', fontSize: '0.8em' }}>{error}</span>}
    </>
  );
}
