import { SET_FORM, IForm } from './types'

//As actions servem para facilitar a chamada aos reducers
export function setForm (form: IForm) {
  return {
    type: SET_FORM,
    payload: form
  }
}

export function setFormItem (item: {key: string, value: any}) {
  return {
    type: 'SET_FORM_ITEM',
    payload: item
  }
}

export function setFormPersonType (person: string) {
  return {
    type: 'SET_FORM_PERSON_TYPE',
    payload: person
  }
}