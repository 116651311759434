import { VehicleTypes, IVehicle } from './types'

const _initialState: IVehicle = {
  type: '',
  brandId: '',
  brandName: '',
  id: '',
  name: '',
  fipeId: ''
}

const resources = (state = _initialState, action: VehicleTypes ) => {
  switch (action.type) {
    //Handler de actions, responsável por setar o state.
    case 'SET_VEHICLE': {
      return {
        ...state,
        ...action.payload
      }
    }
    default:
      return state
  }
}

export default resources