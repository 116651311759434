import React, { useEffect, useRef } from "react";

import { useField } from "@unform/core";
import { InputProps } from "@chakra-ui/react";
import Input from "../Input";
import { InputMask } from "../../types";
import theme from "../../assets/styles/theme";

interface Props extends Omit<InputProps, "name"> {
  name: string
  mask?: InputMask
  leftAddon?: string
  rightAddon?: string
  defaultValue?: string | number
  error?: string
}

export default function FormInput({
  name,
  defaultValue,
  error,
  ...rest
}: Props) {
  const inputRef = useRef(null);

  const { fieldName, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,

      ref: inputRef.current,

      path: "value",
    });
  }, [fieldName, registerField]);

  return (
    <Input
      isInvalid={Boolean(error && error.trim() !== "")}
      errorBorderColor={theme.colors.red["400"]}
      ref={inputRef}
      defaultValue={defaultValue}
      error={error}
      {...rest}
    />
  );
}
