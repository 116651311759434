import { ReactElement } from 'react'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import theme from '../../assets/styles/theme'

const ReactSwal = withReactContent(Swal)

export interface IAlert {
  title?: string
  type?: "success" | "warning" | "error" | "info"
  body?: string | ReactElement
  force?: boolean
  showCancelButton?: boolean
  confirmButtonText?: string
  cancelButtonText?: string
  input?: boolean,
  inputPlaceholder?: string,
  onConfirm?: Function
  onCancel?: Function
}


const anySwalOpen = () => {
  const classes = document.body.classList
  return classes.contains('swal2-shown')
}

const Alert = {
  fire: (
    {
      type = "info",
      title = "",
      body = "",
      onConfirm,
      onCancel,
      showCancelButton = false,
      input = false,
      confirmButtonText = "Ok",
      cancelButtonText = "Cancelar",
      force = false
   }: IAlert) => {

    if(anySwalOpen() && !force) return

    const isString = typeof body === 'string'
    const isReactElement = typeof body === 'object'

    if (!isString && !isReactElement)
      throw new Error("Body must be either string or an Element.")

    ReactSwal.fire({
      icon: type,
      title: title,
      text: isString ? body as string : undefined,
      html: isReactElement ? body : undefined,
      heightAuto: false,
      showCancelButton: showCancelButton,
      confirmButtonText,
      cancelButtonText,
      cancelButtonColor: theme.colors.red['500'],
      input: input ? "textarea" : "text" 

    }).then((result) => {
      if (result.value) {
        if (onConfirm) {
          onConfirm()
        }
      } else {
        if (result.dismiss === Swal.DismissReason.cancel && onCancel) {
          onCancel()
        }
      }
    })
  }
}

export default Alert