import styled from "@emotion/styled"

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

export const Content = styled.div`
  width: 100%;
`

export const SimulationPageHeaderContent = styled.div`
  width: 100%;
  height: 100%;
  background-color: red;
  color: black;
  display: grid;
  grid-template-columns: 70% 30%;
  
  span{
    font-size: 48px;
  }
`