import React, {ReactElement} from 'react'
import { Switch, useHistory } from 'react-router-dom'
import SimulationRouter from './SimulationRouter'

import Simulator from '../views/Simulator'
import queryString from 'query-string'
import { useDispatch } from 'react-redux'
import { setAgxHash } from '../store/modules/global/actions'
import { getCookies } from '../utils/cookies'

export default function Routes (): ReactElement {
  const history = useHistory()
  const dispatch = useDispatch()
  const search = queryString.parse(history.location.search)
  const cookies: {agxHash?:string} = getCookies()

  if (search?.agx_hash) {
    dispatch(setAgxHash(search?.agx_hash as string))
    if (!['agxHash'].includes(document.cookie) && search?.agx_hash) document.cookie = `agxHash=${search.agx_hash}`
  } else if (cookies?.agxHash) {
    dispatch(setAgxHash(cookies?.agxHash as string))
  }
  
  return (
    <Switch>
      <SimulationRouter exact path='/' component={Simulator} />
    </Switch>
  )
}