
const agx = {
  colors: {
    brand: {
      900: "#1a365d",
      800: "#153e75",
      700: "#2a69ac",
    },
    secondary: {
      50: '#ffeee0',
      100: '#fad2b7',
      200: '#f1b58d',
      300: '#ea9862',
      400: '#f4873b',
      500: '#c9611c',
      600: '#9d4c15',
      700: '#71350e',
      800: '#451f03',
      900: '#1d0800',
    },
    primary: {
      10: '#f8f8f9',
      50: '#e8e8e9',
      100: '#dadad9',
      200: '#c0c0c0',
      300: '#a7a7a7',
      400: '#8e8e8e',
      500: '#747474',
      600: '#5a5a5a',
      700: '#414141',
      800: '#272726',
      900: '#0871b3',
    },
  },
  fullPage: false,
  form: {} as AutoContratacao.IFormParams,
  options: {} as AutoContratacao.IFormParams,
  text: {
    isNew: 'O Imóvel que deseja comprar é?',
    financy: 'Valor do financiamento',
    slider: undefined
  },
  initialStep: false,
  showParcels: false,
  oneStep: false,
  lineServer: undefined
}

export default agx