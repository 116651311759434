import { Step, StepTypes } from './types'
import { layout } from 'config/projectStyle'

const _initialState: Step = {
  active: layout.initialStep ? 'initial' : 'aboutentry',
  pastSteps: []
}

const resources = (state = _initialState, action: StepTypes ) => {
  switch (action.type) {
    //Handler de actions, responsável por setar o state.
    case 'SET_STEP': {
      state.pastSteps.push(state.active)
      return {
        ...state,
        ...action.payload
      }
    }

    case 'SET_PAST_STEPS': {
      const lastPage = state.pastSteps[state.pastSteps.length - 1]
      state.pastSteps.pop()
      return {
        ...state,
        active: lastPage,
      }
    }

    default:
      return state
  }
}

export default resources