import styled from '@emotion/styled'
import theme from '../../assets/styles/theme'

export const RadioWrapper = styled.div`
  input[type="radio"] {
  opacity: 0;
  
  + label {
    position: relative;
    display: inline-block;
    cursor: pointer;
    
    &::before {
      content: '';
      display: inline-block;
      position: absolute;
      left: -20px;
      top: 1px;
      border-radius: 50%;
      background-color: ${theme.colors.white};
      border: 1px solid ${theme.colors.primary[400]};
      width: 18px;
      height: 18px;
    }
    
    &::after {
      content: '';
      position: absolute;
      display: inline-block;
      left: -16px;
      top: 5px;
      border-radius: 50%;
      width: 10px;
      height: 10px;
    }
  }
  
  &:checked {
    + label::after {
      background: ${theme.colors.secondary[400]};
    }
  }
  
  &:focus {
    + label::before {
      box-shadow: 0 0 0 1px ${theme.colors.secondary[400]};
    }
  }
  &:hover {
    + label::before {
      box-shadow: 0 0px 4px ${theme.colors.secondary[400]};
    }
  }
}
`;