export const SET_STEP = 'SET_STEP'
export const SET_PAST_STEPS = 'SET_PAST_STEPS'


export interface Step {
  active: 'initial' | 'aboutentry' | 'location' | 'fgts' | 'result' | 'financy' | 'finish' | 'confirm' | 'error',
  pastSteps: string[]
}

interface setStep {
  type: typeof SET_STEP
  payload: Step
}

interface setPastSteps {
  type: typeof SET_PAST_STEPS
  payload: Step
}

// Exporta as ações possíveis:
//     case 'SET_FORM': {
//   return {
//     ...state,
//     ...action.payload
//   }
// }
// case 'GET_FORM': {
// }
//
// Neste caso GET_FORM mostra erro pois não existe em FormTypes
//
export type StepTypes = setStep | setPastSteps