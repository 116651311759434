import { Flex, Text } from '@chakra-ui/react'
import React, { ReactElement } from 'react'

export interface Step {
  index: number
  title?: string
  value?: string
}

interface Props extends Step{
  indicatorBackground?: string
  indicatorForeground?: string
  titleForeground?: string
  isActive: boolean
}

export default function Indicator({index, title, indicatorBackground, indicatorForeground, titleForeground, isActive}: Props): ReactElement {
  return (
  <Flex direction="row" alignContent='center' alignItems="center">
    <Text color={titleForeground} fontWeight='bold' fontSize='20px'>{title}</Text>
  </Flex>
  )
}
