import {IGlobalsImovel, Loading, SET_LOADING} from './types'

export function setLoading (loading: Loading) {
  return {
    type: SET_LOADING,
    payload: loading
  }
}

export const setAgxHash = (agxHash: string) => ({
  type: 'SET_AGXHASH',
  payload: { agxHash }
})

export const setImovelValues = (global:IGlobalsImovel) => ({
  type: 'SET_IMOVEL_VALUES',
  payload: global
})

