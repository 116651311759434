
const formType = (type: string) => {
  switch(type) {
    case 'residential': return 'residencial'
    case 'commercial': return 'comercial'
    default: return type
  }
}

const exported = {
  formType
}

export default exported