import { Flex } from "@chakra-ui/react";
import React, { ReactElement } from "react";
import Indicator, { Step } from "./Indicator";

interface Props {
  indicatorBackground?: string;
  indicatorForeground?: string;
  titleForeground?: string;
  activeStep: string | 'aboutentry' | 'location' | 'fgts' | 'result'
  steps: Array<Step>;
}

export default function StepIndicator({
  indicatorBackground,
  indicatorForeground,
  titleForeground,
  activeStep,
  steps,
}: Props): ReactElement {
  return (
    <Flex direction="row" alignContent='center' alignItems="center" justifyContent="space-between">
      {steps.map((step: Step) => {
        if (activeStep === step.value) {
          return (
            <Indicator
              key={step.index}
              index={step.index}
              title={step.title}
              isActive={activeStep === step.value}
              indicatorBackground={indicatorBackground}
              indicatorForeground={indicatorForeground}
              titleForeground={titleForeground}
            />
          )
        }

        return <></>
      })}
    </Flex>
  );
}
