import { ProductTypes, IProduct } from './types'

const _initialState: IProduct = {
  slug: undefined,
  sub: undefined
}

const resources = (state = _initialState, action: ProductTypes ) => {
  switch (action.type) {
    //Handler de actions, responsável por setar o state.
    case 'SET_PRODUCT': {
      return {
        ...state,
        ...action.payload
      }
    }
    default:
      return state
  }
}

export default resources