import { SimulatorTypes, ISimulator } from './types'

const _initialState: ISimulator = {
  _id: '',
  number: 0,
  totalInterest: 0,
  totalValue: 0,
  totalAmortization: 0,
  financing: 0,
  deadline: 0,
  anualTax: 0,
  monthlyTax: 0,
  entryValue: 0,
  maxParcelValue: 0,
  minimunIncome: 0,
  paymentParcels: [],
  entryId: '',
  type: 'sac',
  finish: false,
  min: 0,
  max: 0,
  ipca: '',
  iof: undefined,
  extraTax: undefined,
  deadlineMin: 60,
  deadlineMax: 360
}

const resources = (state = _initialState, action: SimulatorTypes ) => {
  switch (action.type) {
    //Handler de actions, responsável por setar o state.
    case 'SET_SIMULATOR': {
      return {
        ...state,
        ...action.payload
      }
    }
    case 'SET_SIMULATOR_POST': {
      return {
        ...state,
        ...action.payload
      }
    }
    default:
      return state
  }
}

export default resources