import { ErrorTypes, IError } from './types'

const _initialState: IError = {}

const resources = (state = _initialState, action: ErrorTypes ) => {
  switch (action.type) {
    //Handler de actions, responsável por setar o state.
    case 'SET_ERROR': {
      return {
        ...action.payload
      }
    }
    default:
      return state
  }
}

export default resources