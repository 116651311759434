import React from 'react'
import { 
  Button, 
  Text, 
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  Box,
  RadioGroup,
  Radio
} from '@chakra-ui/react'
import { TextInput } from 'components/FormInputs';
import { useDispatch } from 'react-redux';

import { setError } from '../../store/modules/error/actions'
import { useError, useForm } from 'hooks';

interface IModalConfirmEmailProps {
  open: boolean
  isLoading: boolean
  confirmEmail?: string
  onClose: () => void
  setConfirmEmail: (value:string) => void
  setChangeEmail: (value:boolean) => void
  onSend: Function
}

function ModalConfirmEmail({open, isLoading, onClose, setConfirmEmail, confirmEmail, setChangeEmail, onSend}: IModalConfirmEmailProps) {
  const dispatch = useDispatch()
  const form = useForm()
  const errors = useError()

  return (
    <Modal isOpen={open} onClose={onClose}>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>Confirmação de e-mail</ModalHeader>
      <ModalCloseButton />

      <ModalBody>
        <Box display={'flex'}  flexWrap={'wrap'} justifyContent={'space-between'}>
          <Text flex={"1 2 300px"} fontSize=".75rem" fontWeight="400">Atenção: Será enviado um e-mail com o acesso para que você possa acompanhar o andamento da sua proposta. Por favor confirme seu e-mail.</Text> 
        </Box>
        
        <TextInput 
          name="confirmEmail" 
          type='email' 
          onFocus={() => dispatch(setError({}))} onChange={(e) => setConfirmEmail(e.target.value)} value={confirmEmail || form.confirmEmail} autoFocus={true} defaultValue={form.confirmEmail} error={errors.email} />

        {
          (errors.email && form.confirmEmail && (form.confirmEmail !== form.email)) && 
          (
            <>
              <Box display={'flex'}  flexWrap={'wrap'}>
                <Text flex={"1 2 300px"} fontSize="1rem" fontWeight="400">Deseja utilizar o e-mail:</Text> 
                <RadioGroup onChange={(e)=> {setConfirmEmail(String(e)); setChangeEmail(String(e) === form.confirmEmail)}}>
                    <Radio w="100%" colorScheme="secondary" value={form.email}>{form.email}</Radio>
                    <Radio w="100%"colorScheme="secondary" value={form.confirmEmail}>{form.confirmEmail}</Radio>
                </RadioGroup>
              </Box>
            </>
          )
        }
      </ModalBody>

      <ModalFooter>
        <Button variant="ghost" mr={3} onClick={onClose}>
          Cancelar
        </Button>
        <Button isLoading={isLoading} colorScheme="secondary" variant="solid" onClick={()=> onSend(true) }>Enviar</Button>
      </ModalFooter>
    </ModalContent>
  </Modal>

  )
}

export default ModalConfirmEmail
