import React, { useEffect, useRef, MutableRefObject } from "react";

import { useField } from "@unform/core";
import { InputMask } from "../../types";
import theme from "../../assets/styles/theme";

interface Props extends Omit<React.InputHTMLAttributes<any>, "name"> {
  name: string
  min: number
  max: number
  mask?: InputMask
  defaultValue?: number
  error?: string
  ref?: MutableRefObject<any>
}

export default function FormInput({
  name,
  defaultValue,
  min,
  max,
  error,
  ref,
  ...rest
}: Props) {
  const inputRef = useRef(null);

  const { fieldName, registerField } = useField(name);

  const color = theme.colors.primary[400]

  document.getElementById('rangeV')?.style.setProperty('--main-bg-color', color)
  document.getElementById('range')?.style.setProperty('--main-bg-color', color)

  useEffect(() => {
    registerField({
      name: fieldName,

      ref: inputRef.current,

      path: "value",
    });
  }, [fieldName, registerField]);

  return (
    <>
      <div className="range-wrap">
        <div className="range-value" id="rangeV"></div>
        <input className="range-input" type="range" min={min} id="range" max={max} defaultValue={0} ref={inputRef} step="1" {...rest}/>
      </div>
      {error && <span style={{ color: '#f00', fontSize: '0.8em' }}>{error}</span>}
    </>
  );
}
