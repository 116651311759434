import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import App from './App'

import { ChakraProvider } from "@chakra-ui/react"
import theme from './assets/styles/theme'
import store, { history } from './store'
// @ts-ignore
import HttpsRedirect from 'react-https-redirect'

import './assets/styles/style.css'
import variables from './config/variables'
import './style/main.scss'

const env = process.env.NODE_ENV || variables.env
let httpsDisabled = (env !== "production")

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <HttpsRedirect disabled={httpsDisabled}>
        <ChakraProvider theme={theme} resetCSS>
          <App />
        </ChakraProvider>
      </HttpsRedirect>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
)