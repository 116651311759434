import React from 'react'
import { TableWarper } from './styled'

interface Props {
  children: React.ReactElement | string | React.ReactElement[]
  title?: string 
}

const Table = ({ title, children, ...rest}: Props) => {
  return (
    <TableWarper>
      <thead>
        { title ? (
            <tr>
              <th colSpan={2}>
                <label >{title}</label>
              </th>
            </tr>
          ) : (<></>)
        }
      </thead>
      <tbody>
        {children}
      </tbody>
    </TableWarper>
  )
}

export default Table