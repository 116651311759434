import React, { useRef, useState, useEffect } from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { useField } from '@unform/core';
import 'react-datepicker/dist/react-datepicker.css'
import { InputMask } from '../../types'
import maskText from '../../utils/masks'
import ptBR from 'date-fns/locale/pt-BR'

interface Props extends Omit<ReactDatePickerProps, 'onChange'> {
  name: string;
  mask?: InputMask
  error?: boolean
}

const DatePicker: React.FC<Props> = ({ name,error, ...rest }) => {
  const datepickerRef = useRef(null);
  const { fieldName, registerField, defaultValue } = useField(name);
  const [date, setDate] = useState(defaultValue || null);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: datepickerRef.current,
      path: 'props.selected',
      clearValue: (ref: any) => {
        ref.clear();
      },
    });
  }, [fieldName, registerField]);
  return (
    <ReactDatePicker
      className={`react-datepicker-custom ${error && 'react-datepicker-custom__error'}`}      
      ref={datepickerRef}
      selected={date}
      onChange={setDate}
      onChangeRaw={(e) => e.target.value = maskText(e.target.value, 'date' as InputMask)}
      locale={ptBR}
      {...rest}
    />
  );
};
export default DatePicker;