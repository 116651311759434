import React, { useState } from 'react'
import { Radio } from "../../components/FormInputs"
import { Button, Grid, GridItem, Text, useBreakpointValue } from "@chakra-ui/react"
import { connect, ConnectedProps } from "react-redux"
import { RootState } from "../../store/modules/rootReducer"

const mapState = (state: RootState) => {
  return {
    form: state.form,
    errors: state.error
  }
}

const connector = connect(mapState)
type PropsFromRedux = ConnectedProps<typeof connector>

const StepTwo = ({ form, errors }: PropsFromRedux): React.ReactElement => {
  const [livesOrWorkOnRealStateCity, setLivesOrWorkOnRealStateCity] = useState<string>(form.livesOrWorkOnRealStateCity) 

  const templateAreas = useBreakpointValue({ 
    lg: `'livesOrWorkOnRealStateCity   livesOrWorkOnRealStateCity   . . .'
    'ownsOnSameCityOrHasActiveSFH ownsOnSameCityOrHasActiveSFH . . .'
    'goBack .   .   . submit'`,
    md: `'livesOrWorkOnRealStateCity   livesOrWorkOnRealStateCity   . .'
    'ownsOnSameCityOrHasActiveSFH ownsOnSameCityOrHasActiveSFH . .'
    'goBack . . submit'`,
    base: `'livesOrWorkOnRealStateCity'
    'ownsOnSameCityOrHasActiveSFH'
    'submit'
    'goBack'`
  })

  return ( 
    <Grid
      padding="8"
      gridGap="2"
      gridTemplateAreas={templateAreas}
    >
      <GridItem gridArea="livesOrWorkOnRealStateCity">
        <Text fontWeight="500">Reside ou trabalha na cidade informada:</Text>
        <Radio
          name="livesOrWorkOnRealStateCity"
          defaultValue={form.livesOrWorkOnRealStateCity}
          options={[
            { label: "Sim", value: 'true' },
            { label: "Não", value: 'false' },
          ]}
          error={errors.livesOrWorkOnRealStateCity}
          onChange={(test) => setLivesOrWorkOnRealStateCity(test.target.value) }
        />
      </GridItem>

      { 
        ['true', true, '1', 1].includes(livesOrWorkOnRealStateCity) ? (
          <GridItem gridArea="ownsOnSameCityOrHasActiveSFH">
            <Text fontWeight="500">Possui imóvel residencial na cidade acima OU possui financiamento imobiliário ativo no SFH?</Text>
            <Radio
              name="ownsOnSameCityOrHasActiveSFH"
              defaultValue={form.ownsOnSameCityOrHasActiveSFH}
              options={[
                { label: "Sim", value: 'true' },
                { label: "Não", value: 'false' },
              ]}
              error={errors.ownsOnSameCityOrHasActiveSFH}
            />
          </GridItem>
        ) : ""
      }

      <Button colorScheme="secondary" variant="solid" type="reset" gridArea="goBack" >
        Voltar
      </Button>

      <Button colorScheme="secondary" variant="solid" type="submit" gridArea="submit">
        Proximo
      </Button>
    </Grid>
  )
}

export default connector(StepTwo)