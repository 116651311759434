export const SET_PRODUCT = 'SET_PRODUCT'

export interface IProduct {
  slug: string | undefined
  sub: string | undefined
}

interface setProduct {
  type: typeof SET_PRODUCT
  payload: IProduct
}

export type ProductTypes = setProduct
