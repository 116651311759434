import { FormTypes, IForm } from './types'
import { layout } from '../../../config/projectStyle'

const _initialState: IForm = {
  type: "",
  value: 0,
  income: 0,
  isNew: layout?.form.isNew || "",
  terms: false,
  cpf: "",
  name: "",
  uf: "ac",
  city: "",
  phone: "",
  familyIncome: "",
  email: '',
  changeEmail: false,
  personType: "",
  firstStepId: undefined,
  secondStepId: undefined,
  thirdStepId: undefined,
}

const resources = (state = _initialState, action: FormTypes ) => {
  switch (action.type) {
    //Handler de actions, responsável por setar o state.
    case 'SET_FORM': {
      return {
        ...state,
        ...action.payload
      }
    }
    case 'SET_FORM_PERSON_TYPE': {
      return {
        ...state,
        personType: action.payload.personType
      }
    }
    case 'SET_FORM_ITEM': {
      return {
        ...state,
        [action.payload.key]: action.payload.value
      }
    }
    default:
      return state
  }
}

export default resources