import { Image, Heading, Box, Flex } from "@chakra-ui/react"
import React from "react"
import logo from "../../assets/images/logo.png"
const SimulationPageHeader = () => {

  return (
    <Flex h="100%">
      <Flex w="60%" alignItems="baseline">
        <Heading fontSize={["2xl", "4xl", "5xl"]} lineHeight="1em" color="white" marginY="8">
          Simule seu financiamento
      </Heading>
      </Flex>
      <Box w="40%" marginY="8">
        <Image src={logo} height="100%" />
      </Box>
    </Flex>
  )
}

export default SimulationPageHeader
