import React, { useEffect, useRef } from "react";

import { useField } from "@unform/core";
import OriginalSelect from "../Select";
import { CustomSelectProps } from "../Select/Select";

interface Props extends Omit<CustomSelectProps, 'name'>{ 
  name: string
  defaultValue?: string
}

export default function Select({ name, defaultValue, ...rest }: Props) {
  const inputRef = useRef(null);

  const { fieldName, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: (ref: any) => {
        if (!ref || !ref.value) {
          return '';
        }
        return ref.value;
      }
    });
  }, [fieldName, registerField]);

  return (
    <>
      <OriginalSelect ref={inputRef} defaultValue={defaultValue} {...rest} size='sm'/>
    </>
  )
}
