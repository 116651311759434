import { SET_SIMULATOR_POST, SET_SIMULATOR, ISimulatorPost, ISimulatorSet } from './types'

export function setSimulatorPost (form: ISimulatorPost) {
  return {
    type: SET_SIMULATOR_POST,
    payload: form
  }
}

export function setSimulator (form: ISimulatorSet) {
  return {
    type: SET_SIMULATOR,
    payload: form
  }
}