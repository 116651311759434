import { InputGroup, InputLeftAddon, InputRightAddon, InputProps } from '@chakra-ui/react'
import React, { MutableRefObject } from 'react'
import { Input as ChakraInput } from "@chakra-ui/react"
import theme from '../../assets/styles/theme'
import maskText from '../../utils/masks'
import { InputMask } from '../../types'

const colors = theme.colors

interface Props extends InputProps{
  mask?: InputMask
  leftAddon?: string
  error?: string
  rightAddon?: string
  defaultValue?: string | number
}

const Input = React.forwardRef(
  ({ size = 'sm', focusBorderColor = colors.secondary['400'], mask, error, leftAddon, rightAddon,defaultValue,...rest}: Props, ref?: MutableRefObject<any> | ((instance: any) => void) | null) =>  {
    return (
    <>
      <InputGroup size={size} >
        {leftAddon && 
        <InputLeftAddon children="R$"  />
        }
        <ChakraInput onChange={(e) => e.target.value = maskText(e.target.value, mask as InputMask)} defaultValue={maskText(defaultValue as string, mask as InputMask, true)} {...rest} ref={ref}  focusBorderColor={focusBorderColor} bg={colors.primary['10']}/>
        {rightAddon &&
        <InputRightAddon children={rightAddon} />
        }
      </InputGroup>
      {error && <span style={{ color: '#f00', fontSize: '0.8em' }}>{error}</span>}
    </>
  )
})

export default Input
