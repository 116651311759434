import { extendTheme } from "@chakra-ui/react"
import { layout } from '../../config/projectStyle'

//console.log(layout)
const colors = layout?.colors

const fonts = {
  body: "Heebo , sans-serif, system-ui",
  heading: "Heebo, Georgia, sans-serif",
  mono: "Menlo, monospace",
}

const theme = extendTheme({ colors, fonts })

export default theme