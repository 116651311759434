/* eslint-disable react/prop-types */
import { useField } from '@unform/core';
import React, { MutableRefObject, useEffect, useRef } from 'react'
import CurrencyInput from 'react-currency-input-field'

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> { 
  loading?: boolean, 
  inputRef?: MutableRefObject<any>, 
  rest?: any, 
  onValueChange?: any,
  error?: string
  name: string
  defaultValue?: number
}

function InputMoney ({ className, onChange, loading, error, name, defaultValue,  ...rest }: InputProps) {
  const inputRef = useRef(null);

  const { fieldName, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,

      ref: inputRef.current,

      path: "value",
    });
  }, [fieldName, registerField]);

  return (
    loading
      ? <></>
      : <>
      <CurrencyInput
      {...(rest as any)}
      ref={inputRef}
      prefix="R$ "
      defaultValue={defaultValue}
      onValueChange={onChange}
      className={`react-datepicker-custom ${error && 'react-datepicker-custom__error'}`}
      decimalsLimit={2}
      decimalSeparator=","
      groupSeparator="."
      onInput={(e) => {
        const value = (e.target as HTMLInputElement).value
        const numericalValue = Number(value.match(/[\d^,]/gi)?.join(''))
        const fractionalValue = value.match(/,\d+$/gi)?.join('') || ''
        if (numericalValue > 999999999 || fractionalValue.length > 3) {
          (e.target as HTMLInputElement).value = value.slice(0, -1)
        }
      }}
    />
    {error && <span style={{ color: '#f00', fontSize: '0.8em' }}>{error}</span>}
    </>
  )
}

export default InputMoney
