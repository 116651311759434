import variables from "../variables"

const rodobens = {
  colors: {
    primary: {
      10: '#ffffff',
      50: '#f7f7f7',
      100: '#195b9c',
      200: '#8fc0f0',
      300: '#64a7e9',
      400: '#3b8ee1',
      500: '#2475c8',
      600: '#bad9f9',
      700: '#0f4170',
      800: '#042745',
      900: '#000e1b',
    },
    secondary: {
      50: '#f7fdde',
      100: '#e8f7b6',
      200: '#daf18b',
      300: '#cceb5f',
      400: '#bee533',
      500: '#a4cc1a',
      600: '#809e11',
      700: '#5a7108',
      800: '#364402',
      900: '#101800',
    }
  },
  fullPage: true,
  form: {
    isNew: "false"
  } as AutoContratacao.IFormParams,
  options: {
    isNew: [
      { label: "Usado", value: "false" },
    ]
  } as AutoContratacao.IFormParams,
  text: {
    isNew: 'O seu imóvel é?',
    financy: 'Valor de crédito disponível',
    slider: undefined
  },
  initialStep: true,
  oneStep: false,
  slug: 'rodobens',
  showParcels: true,
  lineServer: variables.rodobensServer || 'https://rodobens-production-server.herokuapp.com'
}

export default rodobens