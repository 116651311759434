import React, { useEffect } from 'react';
import { Dispatch } from "redux"
import { connect, ConnectedProps } from 'react-redux'
import queryString from 'query-string'

import Routes from './routes'

import { ILine } from './store/modules/line/types';
import { setLine } from './store/modules/line/actions'
import { RootState } from './store/modules/rootReducer';

const mapState = (state: RootState) => ({ line: state.line })

const mapDispatch = (dispatch: Dispatch) => ({ 
  setLine: (line: ILine) => dispatch(setLine(line))
})

type PropsFromRedux = ConnectedProps<typeof connector>

const connector = connect(mapState, mapDispatch)

function App({ setLine }: PropsFromRedux) {

  useEffect(() => {
    const path = window.location.pathname
    const internal = /internal/.test(path)

    const createObj: ILine = {
      internal,
      clientId: '',
      productId: '',
      token: ''
    } 

    const params = queryString.parse(window.location.search)
    if(params) {
      createObj.clientId = params.clientId as string
      createObj.productId = params.productId as string
      createObj.token = params.token as string
    }

    setLine(createObj)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  return (
    <Routes />
  );
}

export default connector(App)
